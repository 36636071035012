@import "../partials/variables";
@import "./mixins.scss";

$order-created: lighten($green, 5%);
$order-accepted: $green;
$order-partially-accepted: $green;
$order-partially-packed: lighten($blue, 5%);
$order-packed: darken($order-partially-packed, 5%);
$order-at-cold-store: darken($order-packed, 5%);
$order-at-rail-depot: $order-at-cold-store;
$order-on-vessel: darken($order-at-cold-store, 5%);
$order-in-transit: darken($order-at-cold-store, 5%);
$order-at-destination-port: darken($order-in-transit, 5%);
$order-at-origin-port: darken($order-at-destination-port, 5%);
$order-partially-delivered: darken($order-at-origin-port, 5%);
$order-delivered: darken($order-partially-delivered, 5%);
$order-wont-pack: $red;
$order-expired: $orange;

/*  TODO: remove once v2 complete */
.progress {
  &.is-order-created::-webkit-progress-value {
    background: $order-created !important;
  }

  &.is-order-created::-moz-progress-bar {
    background: $order-created !important;
  }

  &.is-order-accepted::-webkit-progress-value {
    background: $order-accepted !important;
  }

  &.is-order-accepted::-moz-progress-bar {
    background: $order-accepted !important;
  }

  &.is-order-packed::-webkit-progress-value {
    background: $order-packed !important;
  }

  &.is-order-packed::-moz-progress-bar {
    background: $order-packed !important;
  }

  &.is-order-at-cold-store::-webkit-progress-value {
    background: $order-at-cold-store !important;
  }

  &.is-order-at-cold-store::-moz-progress-bar {
    background: $order-at-cold-store !important;
  }

  &.is-order-at-rail-depot::-webkit-progress-value {
    background: $order-at-rail-depot !important;
  }

  &.is-order-at-rail-depot::-moz-progress-bar {
    background: $order-at-rail-depot !important;
  }

  &.is-order-on-vessel::-webkit-progress-value {
    background: $order-on-vessel !important;
  }

  &.is-order-on-vessel::-moz-progress-bar {
    background: $order-on-vessel !important;
  }

  &.is-order-at-destination-port::-webkit-progress-value {
    background: $order-at-destination-port !important;
  }

  &.is-order-at-destination-port::-moz-progress-bar {
    background: $order-at-destination-port !important;
  }

  &.is-order-delivered::-webkit-progress-value {
    background: $order-delivered !important;
  }

  &.is-order-delivered::-moz-progress-bar {
    background: $order-delivered !important;
  }
}

.is-order-created {
  @include tag-color($order-created);
}

.is-order-accepted {
  @include tag-color($order-accepted);
}

.is-order-partially-accepted {
  @include tag-color($order-partially-accepted);
}

.is-order-partially-packed {
  @include tag-color($order-partially-packed);
}

.is-order-packed {
  @include tag-color($order-packed);
}

.is-order-at-cold-store {
  @include tag-color($order-at-cold-store);
}

.is-order-at-rail-depot {
  @include tag-color($order-at-rail-depot);
}

.is-order-on-vessel {
  @include tag-color($order-on-vessel);
}

.is-order-in-transit {
  @include tag-color($order-in-transit);
}

.is-order-at-destination-port {
  @include tag-color($order-at-destination-port);
}

.is-order-at-origin-port {
  @include tag-color($order-at-origin-port);
}

.is-order-delivered {
  @include tag-color($order-delivered);
}

.is-order-partially-delivered {
  @include tag-color($order-partially-delivered);
}

.is-order-wont-pack {
  @include tag-color($order-wont-pack);
}

.is-order-expired {
  @include tag-color($order-expired);
}
