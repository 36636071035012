@import "../partials/variables";
@import "./mixins.scss";

$line-item-new: $blue;
$line-item-created: lighten($green, 10%);
$line-item-accepted: $green;

$line-item-partially-packed: lighten($blue, 20%);
$line-item-packed: darken($line-item-partially-packed, 5%);
$line-item-at-cold-store: darken($line-item-packed, 5%);
$line-item-at-rail-depot: $line-item-at-cold-store;
$line-item-at-origin-port: darken($line-item-at-cold-store, 5%);

/* TODO: remove once v2 complete */
$line-item-on-vessel: darken($line-item-at-cold-store, 5%);

$line-item-in-transit: darken($line-item-at-origin-port, 5%);
$line-item-at-destination-port: darken($line-item-in-transit, 5%);
$line-item-delivered: darken($line-item-at-destination-port, 5%);

/* TODO: remove once v2 complete */
$line-item-payment: darken($line-item-delivered, 5%);
/* TODO: remove once v2 complete */
$line-item-completed: darken($line-item-payment, 5%);

$line-item-rejected: $red;
$line-item-wont-pack: $red;

$line-item-expired: $orange;

.progress {
  &.is-line-item-created::-webkit-progress-value {
    background: $line-item-created !important;
  }

  &.is-line-item-created::-moz-progress-bar {
    background: $line-item-created !important;
  }

  &.is-line-item-accepted::-webkit-progress-value {
    background: $line-item-accepted !important;
  }

  &.is-line-item-accepted::-moz-progress-bar {
    background: $line-item-accepted !important;
  }

  &.is-line-item-packed::-webkit-progress-value {
    background: $line-item-packed !important;
  }

  &.is-line-item-packed::-moz-progress-bar {
    background: $line-item-packed !important;
  }

  &.is-line-item-at-cold-store::-webkit-progress-value {
    background: $line-item-at-cold-store !important;
  }

  &.is-line-item-at-cold-store::-moz-progress-bar {
    background: $line-item-at-cold-store !important;
  }

  &.is-line-item-at-rail-depot::-webkit-progress-value {
    background: $line-item-at-rail-depot !important;
  }

  &.is-line-item-at-rail-depot::-moz-progress-bar {
    background: $line-item-at-rail-depot !important;
  }

  &.is-line-item-at-origin-port::-webkit-progress-value {
    background: $line-item-at-origin-port !important;
  }

  &.is-line-item-at-origin-port::-moz-progress-bar {
    background: $line-item-at-origin-port !important;
  }

  &.is-line-item-in-transit::-webkit-progress-value {
    background: $line-item-in-transit !important;
  }

  &.is-line-item-in-transit::-moz-progress-bar {
    background: $line-item-in-transit !important;
  }

  /* TODO: remove once v2 complete */
  &.is-line-item-on-vessel::-webkit-progress-value {
    background: $line-item-on-vessel !important;
  }

  &.is-line-item-on-vessel::-moz-progress-bar {
    background: $line-item-on-vessel !important;
  }

  &.is-line-item-at-destination-port::-webkit-progress-value {
    background: $line-item-at-destination-port !important;
  }

  &.is-line-item-at-destination-port::-moz-progress-bar {
    background: $line-item-at-destination-port !important;
  }

  &.is-line-item-delivered::-webkit-progress-value {
    background: $line-item-delivered !important;
  }

  &.is-line-item-delivered::-moz-progress-bar {
    background: $line-item-delivered !important;
  }

  /* TODO: remove once v2 complete */
  &.is-line-item-payment::-webkit-progress-value {
    background: $line-item-payment !important;
  }

  &.is-line-item-payment::-moz-progress-bar {
    background: $line-item-payment !important;
  }

  /* TODO: remove once v2 complete */
  &.is-line-item-completed::-webkit-progress-value {
    background: $line-item-completed !important;
  }

  &.is-line-item-completed::-moz-progress-bar {
    background: $line-item-completed !important;
  }
}

.is-line-item-new {
  @include tag-color($line-item-new);
}

.is-line-item-created {
  @include tag-color($line-item-created);
}

.is-line-item-accepted {
  @include tag-color($line-item-accepted);
}

.is-line-item-partially-packed {
  @include tag-color($line-item-partially-packed);
}

.is-line-item-packed {
  @include tag-color($line-item-packed);
}

.is-line-item-at-cold-store {
  @include tag-color($line-item-at-cold-store);
}

.is-line-item-at-rail-depot {
  @include tag-color($line-item-at-rail-depot);
}

.is-line-item-at-origin-port {
  @include tag-color($line-item-at-origin-port);
}

/* TODO: remove once v2 complete */
.is-line-item-on-vessel {
  @include tag-color($line-item-on-vessel);
}

.is-line-item-in-transit {
  @include tag-color($line-item-in-transit);
}

.is-line-item-at-destination-port {
  @include tag-color($line-item-at-destination-port);
}

.is-line-item-delivered {
  @include tag-color($line-item-delivered);
}

/* TODO: remove once v2 complete */
.is-line-item-paid {
  @include tag-color($line-item-payment);
}

/* TODO: remove once v2 complete */
.is-line-item-completed {
  @include tag-color($line-item-completed);
}

.is-line-item-rejected {
  @include tag-color($line-item-rejected);
}

.is-line-item-wont-pack {
  @include tag-color($line-item-rejected);
}

.is-line-item-expired {
  @include tag-color($line-item-expired);
}
