@import "../partials/variables";
@import "./mixins.scss";

$to-pack: $green;
$overdue: $red;
$packed: $blue;

.is-pack-instructions-to-pack {
  @include tag-color($to-pack);
}

.is-pack-instructions-partially-packed {
  @include tag-color($to-pack);
}

.is-pack-instructions-overdue {
  @include tag-color($overdue);
}

.is-pack-instructions-packed {
  @include tag-color($packed);
}

.is-pack-instructions-packhouse-run {
  @include tag-color($to-pack);
}
