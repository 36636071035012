/* Set your own initial variables */
$family-sans-serif: "Twemoji Country Flags", "Maven Pro", sans-serif;

/* Default Colours */
$white: #fafafa;
$black: #111;
$red: #d23e3e;
$green: #2fbd76;
$blue: #3392ff;
$orange: #f68b1f;
$purple: #6b2468;
