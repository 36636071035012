/* Change Default Bulma Badge Shape (pill) */
.badge[data-badge]:after {
  border-radius: 1rem;
  line-height: 0.5rem;
  top: 1px;
  right: -2px;
  padding: 0.3rem 0.5rem;
}

/* Change Default Bulma Badge Colour */
.badge.is-badge-primary[data-badge]::after {
  background: $blue;
  color: $white;
}
