[data-tooltip] {
  position: relative;
  cursor: pointer;
  &:before, &:after {
    display: none;
    opacity: 0;
    z-index: 999;
    position: absolute;
    pointer-events: none;
    box-sizing: border-box;
    left: 50%;
    transform: translateX(-50%);
  }
  /* arrow */
  &:before {
    content: '';
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 10px solid $primary;
    bottom: 100%;
  }
  /* text */
  &:after {
    font-size: 13px;
    line-height: 1;
    content: attr(data-tooltip);
    text-align: center;
    min-width: 3em;
    max-width: 15ch;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 6px 8px;
    border-radius: 3px;
    background: $primary;
    color: $white;
    bottom: calc(100% + 10px);
  }
  &:hover:after, &:hover:before {
    display: block;
    opacity: 1;
  }
}
[data-tooltip=""]::after, [data-tooltip=""]::before {
  display: none;
  opacity: 0;
}


