@import "../partials/variables";
@mixin background-opacity($color, $opacity: 0.4) {
  background: rgba($color, $opacity) !important;
}

@mixin tag-color($color) {
  @include background-opacity($color);
  color: $black !important;
  font-weight: 600;
  letter-spacing: 0.5px;
}
